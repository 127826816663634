// Box shadows
@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow;
     -moz-box-shadow: $shadow;       
          box-shadow: $shadow;
}

@mixin text-shadow($shadow...) {
  -webkit-text-shadow: $shadow;
     -moz-text-shadow: $shadow;       
          text-shadow: $shadow;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}



.Company-Chain-Details, .Broker-Office-Details, .Broker-Details {
  table {
    td {
      padding: 5pt;
    }

    td:nth-child(1) {
      color: #777;
    }

    td:nth-child(2) {
      padding-left: 10pt;
      font-weight: 400;
    }
  }
}


$dialogWidth: 600px;
$dialogHeight:550px;

.New-Adjustment-Dialog {
  width:$dialogWidth;
  height:$dialogHeight;
  background:white;
  @include box-shadow(3px 3px 6px rgba(#000, .16)) ;

  padding: 10pt;
  
  position:fixed; 
  left: calc(50% - 300px);
  top: 100px;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr 50px;


  .dropbox {
    border: 3px dashed #D2D2D2;
    height:100px;
    background-repeat:no-repeat;
    background-position: center center;
    //background-size: 143px 80px;
  }
}