
     

      .react-autosuggest__container { 

      }

      .react-autosuggest__suggestions-container--open {
          background: white;
          //width:20vw;
          position:absolute;    
          max-height: 50vh;
          overflow-y: auto;    
          border:1px solid #dddddd;
      }

      .react-autosuggest__suggestions-list {
        margin: 0px;
        padding:0px;
        
      }

      .react-autosuggest__suggestion {
        list-style-type: none;
        margin:0px;
        padding:3px;
        cursor: default;

        .inactive {
          color: #aaaaaa;
          text-decoration: line-through;
        }
      }

      .react-autosuggest__suggestion--highlighted {
        background: #fba739;
      }



      /*
        container:                'react-autosuggest__container',
        containerOpen:            'react-autosuggest__container--open',
        input:                    'react-autosuggest__input',
        inputOpen:                'react-autosuggest__input--open',
        inputFocused:             'react-autosuggest__input--focused',
        suggestionsContainer:     'react-autosuggest__suggestions-container',
        suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
        suggestionsList:          'react-autosuggest__suggestions-list',
        suggestion:               'react-autosuggest__suggestion',
        suggestionFirst:          'react-autosuggest__suggestion--first',
        suggestionHighlighted:    'react-autosuggest__suggestion--highlighted',
        sectionContainer:         'react-autosuggest__section-container',
        sectionContainerFirst:    'react-autosuggest__section-container--first',
        sectionTitle:             'react-autosuggest__section-title'*/