// Box shadows
@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin text-shadow($shadow...) {
  -webkit-text-shadow: $shadow;
  -moz-text-shadow: $shadow;
  text-shadow: $shadow;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

$GapWidth: 20px;

.View-Estate-Root-Layout {
  position: absolute;
  left: 0px;
  top: 0px;
  padding-top: 100px;
  width: 100%;
  padding-bottom: 50px;
  background-color: white;
}

.View-Estate-Styling {
  .Section-Header {
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;

    .Section-Title {
      padding-right: 5px;
      color: #212121;
      font-size: 14px;
      flex-grow: 1;
      align-self: center;
    }

    .Section-Spacer {
      flex-grow: 100;
      background-repeat: repeat-x;
      background-position: center center;
    }

    .Section-Control-Element {
      width: 40px;

      .Switch-Container {
        margin-top: -3px;
        display: inline-block;
      }
    }
  }

  .Section-Header.No-Margin {
    margin-top: 0px;
  }

  .Customer-List-Container {
    max-height: 220px;
    overflow: auto;
  }

  table.Customer-List-Table {
    font-family: Roboto, Arial, Helvetica;
    width: 100%;


    tbody {
      margin-bottom: 10px;
    }
    
    tbody td {
      vertical-align: top;
      font-size: 12px;
    }

    tbody td.label {
      color: #b0b0b0;
    }

    tbody td.data {
      color: #212121;
    }

    strong {
      font-size:14px;
      font-weight: 400;
    }
  }

  table.Event-List-Table {
    font-family: Roboto, Arial, Helvetica;
    font-size: 14px;
    width: 100%;

    thead {
      color: #b0b0b0;
      font-size: 12px;
    }

    tbody td {
      vertical-align: top;
      color: #212121;
      font-size: 12px;
    }

    td:nth-child(1) {
    }
    td:nth-child(2) {
      font-weight: 400;
    }
    td:nth-child(3) {
    }
    td:nth-child(4) {
    }
  }

  table.Property-Info {
    font-family: Roboto, Arial, Helvetica;
    font-size: 14px;

    td {
      //min-width: 150px;
      vertical-align: top;
    }

    td:nth-child(1) {
      padding-right: 20px;
      color: #b0b0b0;
    }
    td:nth-child(2) {
      font-weight: 400;
      color: #4e4e4e;
    }
    td:nth-child(3) {
      padding-left:20px;
      padding-right: 20px;
    }
    td:nth-child(4) {
      font-weight: 400;
      color: #4e4e4e;
    }
  }

  table.Compact-Property-Info {
    font-family: Roboto, Arial, Helvetica;
    font-size: 14px;
    width:100%;

    td {
      vertical-align: top;
    }

    td:nth-child(odd) {
      width:6%;
      color: #b0b0b0;
    }
    td:nth-child(even) {
      width:14%;
      font-weight: 400;
      color: #4e4e4e;
    }
  }


  .Summary-Settings-Area {
    margin-bottom: 5px;
    display: grid;
    grid-template-columns: 1fr 200px;

    .Link-Container {
      span {
        padding-right: 5px;
        color: #212121;
        font-size: 14px;
      }
      a {
        font-weight: 400;
      }
    }

    .Settings-Control-Element {
      text-align: right;

      // fix for compact checkbox control
      label.MuiFormControlLabel-root {
        margin-right: 0px;

        span {
          padding: 0px;
          padding-left: 5px;
        }
      }
    }
  }

  .Inline-Block {
    display: inline-block;
  }


  .Message-Table {
    display: table;
    width: 100%;
    font-family: Roboto, Arial, Helvetica;
    margin-top: 15px;

    .MuiSvgIcon-root {
      vertical-align: middle;
    }  

    button {
      padding-top: 7px;
      padding-bottom: 7px;
    }

    .Table-Row {
      display: table-row;

      .Table-Cell-1 {
        display: table-cell;
        width: 20%;
        color: #b0b0b0;
        font-size: 14px;      
      }
      .Table-Cell-2 {
        display: table-cell;
        width: 50%;
        color: #b0b0b0;
        font-size: 14px;

        input.Protocol-Link {
          font-family: Roboto, Arial, Helvetica;
          font-size: 14px;
          display:inline-block;
          margin: 0px;
          padding: 5px;
          border: 1ox solid #eeeeee;
        }
      }


      .Table-Cell-3 {
        display: table-cell;
        width: 10%;
        text-align: left;
        padding-left: 5px;


        .Copy-Link {

          display: inline-block;

          a {
            padding: 5px;
            border: 1px solid white;
          }

          a:active {
            border: 1px solid #9d27b1;
          }
        }

        .Protocol-Status-Icon {
          display: inline-block;
          
          div {
            position: relative;
            top: 7px;
          }
        }                
      }

      .Table-Cell-4 {
        display: table-cell;
        width: 20%;


        button {
          width: 100%;
        }
      }
    }
  }  

  .Message-Table.unsupported {
    background: repeating-linear-gradient(
      45deg,
      white,
      white 10px,
      #fdf1f5 10px,
      #fdf1f5 20px
    );
  }




  .Custom-Message-Table {
    display: table;
    width: 100%;
    font-family: Roboto, Arial, Helvetica;
    margin-top: 15px;

    button {
      padding-top: 7px;
      padding-bottom: 7px;
    }

    .Table-Row {
      display: table-row;

      .Table-Cell-1 {
        display: table-cell;
        width: 20%;
        color: #b0b0b0;
        font-size: 14px;
      }

      .Table-Cell-2 {
        display: table-cell;
        width: 50%;
        color: #b0b0b0;
        font-size: 14px;
        }

      .Table-Cell-3 {
        display: table-cell;
        width: 10%;              
      }

      .Table-Cell-4 {
        text-align: center;
        display: table-cell;
        width: 20%;

        button {
          width: 100%;
        }
      }

    }



  }








}







.Sms-Recipients-Popup {

  padding:20px;



  >div {
    margin-top:3px;
    margin-bottom:3px;
  }

  /* override fix for compact checkbox */
  .MuiCheckbox-root {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  /* override fix for checkbox label */
  .MuiFormControlLabel-label {
    font-family: Roboto, Arial, Helvetica;
    color: #212121;
    font-weight:100;

    strong {
      font-weight:400;
      display:inline-block;
      margin-left:5px;
      margin-right:5px;
    }
  }    
}
