@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

$SearchTagsBackground: #9c26b0;


  
.Search-Tag {
    background-color: $SearchTagsBackground;
    padding-left:8px;
    color:white;
    display:inline-block;
    font-family: Roboto, Helvetica;
    font-size: 11px;
    margin-left:1px;
    margin-bottom: 1px;
    @include border-radius(2px);

    .text {
      display:inline-block;
      padding-bottom:1px;
    }

    .icon {
      padding:2px 5px 0px 5px;
      float:right;
      height:20px;
      margin-left:10px;

      .MuiSvgIcon-root {
        width:0.5em;
        height:0.5em;
      }
    }

    .icon:hover {
      background: lighten($SearchTagsBackground,15%);
    }    
}
