@keyframes textAnimation {
    to {
        background-size: 100% 100%;
    }
}

// Box shadows
@mixin box-shadow($shadow...) {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    box-shadow: $shadow;
}

@mixin text-shadow($shadow...) {
    -webkit-text-shadow: $shadow;
    -moz-text-shadow: $shadow;
    text-shadow: $shadow;
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

// Theme breakpoints
$mdBreakpoint: 960px;

$topMenuBackgroundColor: #4e4e4e;
$AppTabsDefaultBackground: #4e4e4e;
$AppTabsActiveBackground: #2dbcd5;

// snackbar styling

$snackbarStart: 50px;
$snackbarOffset: 90px;

// start debug settings
$debugMenuOpacity: 1;
// end debug settings

//-------------Generic styling--------------------
body {
    margin: 0px;
}

.header {
    &-error,
    &-loading,
    &-default {
        margin-top: 200px;
        text-align: center;
    }

    &-loading {
        display: inline-block;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        background: linear-gradient(#aaa, #000) left no-repeat #999;
        background-size: 0% 100%;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent;
        animation: textAnimation 3s forwards linear infinite;
    }

    &-error {
        color: red;
    }
}

.pagination {
    .Standard-Page-Content & {
        &-wrapper {
            text-align: center;
        }
        &-content {
            color: #777;
        }
        &-bottomContainer {
            margin: 5px 0 10px;
        }
    }
}

.dateTime {
    .Standard-Page-Content & {
        &-wrapper {
            padding-top: 26px;
        }
    }
}

.expandable {
    .companyChainAccountingTable & {
        &-wr {
            padding: 0 !important;
        }
        &-content {
            padding: 0px 8px;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s ease-out, padding 0.3s ease-out;

            &[style] {
                padding: 12px 8px;
            }
        }
    }
}
//-------------End generic styling----------------

/*
 Invisible element used to reset the page scroll on every route change
*/
.Top-Scroll-Anchor {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1px;
    height: 1px;
    z-index: 1000001;
}

.App-Navbar {
    background-color: $topMenuBackgroundColor;
    padding: 0 0 0 10px;
    margin: 0px;
    height: 50px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    z-index: 1000002;
    opacity: $debugMenuOpacity;

    @media (min-width: $mdBreakpoint) {
        display: flex;
        justify-content: center;
    }

    > .navLinks {
        > a {
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            display: inline-block;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 10px;
            padding-bottom: 10px;
            font-size: 14px;
            @include border-radius(4px);
            text-transform: uppercase;
            text-decoration: none;
            margin: 5px;
            color: white;
            background-color: none;

            &:hover {
                color: white;
                background-color: #262323;
            }

            &:active {
                background-color: #4e4848;
            }

            &.current {
                color: black;
                background-color: white;
                @include text-shadow(1px 1px 3px rgba(#000, 0.3));
            }
        }
    }

    > .userMenu {
        position: absolute;
        right: 0;
        top: 0;
        padding-right: 2%;
    }

    > .logo {
        position: absolute;
        left: 0px;
        top: 0px;
        background: white;
        height: 50px;
        padding: 0 30px;
        text-align: left;
        color: $topMenuBackgroundColor;

        .line1 {
            margin-top: 5px;
            font-size: 10pt;
            font-weight: 400;
        }

        .line2 {
            font-size: 18pt;
            text-transform: uppercase;
        }

        @media (max-width: $mdBreakpoint) {
            padding: 0 5px;

            .line1,
            .line2 {
                width: 60px;
                font-size: 10px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            + .navLinks {
                padding-left: 75px;
            }
        }
    }
}

.App-Navbar > .logo-test {
    background: red;
    color: white;
    padding-left: 100px;
    padding-right: 100px;

    .line1 {
        margin-top: 2px;
        font-size: 10pt;
        font-weight: 100;
    }

    .line2 {
        font-size: 22pt;
        font-weight: 400;
    }
}

.App-Tabs {
    position: fixed;
    left: 0px;
    top: 52px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100vw;
    z-index: 1000003;
    opacity: $debugMenuOpacity;

    a {
        cursor: default;
        background-color: $AppTabsDefaultBackground;
        padding-left: 8px;
        color: white;
        display: inline-block;
        font-family: Roboto, Helvetica;
        font-size: 11px;
        margin-left: 1px;
        margin-bottom: 1px;
        @include border-radius(2px);

        .text {
            display: inline-block;
            padding-bottom: 1px;
        }

        .icon {
            padding: 2px 5px 0px 5px;
            float: right;
            height: 20px;
            margin-left: 10px;

            .MuiSvgIcon-root {
                width: 0.5em;
                height: 0.5em;
            }
        }

        .icon:hover {
            background: lighten($AppTabsDefaultBackground, 15%);
        }
    }

    a:hover {
        color: white;
        background-color: lighten($AppTabsDefaultBackground, 5%);
    }

    a.current {
        background-color: $AppTabsActiveBackground;

        .icon:hover {
            background: lighten($AppTabsActiveBackground, 15%);
        }
    }
}

.App-Progress-Bar {
    position: fixed;
    top: 50px;
    left: 0px;
    width: 100%;
    height: 2px;
    overflow: hidden;
    z-index: 1000004;
}

.Full-Page-Overlay {
    position: fixed;
    top: 50px;
    left: 0px;
    // allow menu, tabs etc. on top
    z-index: 100;
    width: 100%;
    height: calc(100vh - 50px);
    opacity: 0.2;
    background: black;
}

.Popup-Z-Index {
    z-index: 106;
}

.Popup-Confirmation-Z-Index {
    z-index: 1000007 !important; // must override Mui-dialog z-index
}

.Send-Email-Window {
    z-index: 1000008;
}

.Standard-Page-Content {
    position: absolute;
    top: 100px;
    left: 0px;
    width: 96%;
    margin-left: 2%;
}

.Custom-Snackbar-0 {
    top: $snackbarStart + $snackbarOffset * 0 !important;
    z-index: 2000000 !important;
}

.Custom-Snackbar-1 {
    top: $snackbarStart + $snackbarOffset * 1 !important;
    z-index: 2000001 !important;
}

.Custom-Snackbar-2 {
    top: $snackbarStart + $snackbarOffset * 2 !important;
    z-index: 2000002 !important;
}

.Custom-Snackbar-3 {
    top: $snackbarStart + $snackbarOffset * 3 !important;
    z-index: 2000003 !important;
}

.Custom-Snackbar-4 {
    top: $snackbarStart + $snackbarOffset * 4 !important;
    z-index: 2000004 !important;
}

.react-autosuggest__suggestions-container--open {
    z-index: 2000005 !important;
}

.Responsible-User-Form {
    padding-left: 15px;
    .MuiFormControl-root {
        margin: 0px !important;
    }

    #mui-component-select-responsibleUserSelect {
        text-transform: none;
    }
}

.estateListPage {
    &-noFilter {
        &-header {
            text-align: center;
            min-height: 150px;
            line-height: 90px;
            color: #cccccc;
        }
    }
}

.orderListPage {
    &.Standard-Page-Content & {
        &-addOrder {
            &-btn {
                padding: 5px 15px;
                float: right;
                font-size: 20px;
            }
        }
    }
}

.brokerChainEditPage {
    &.Standard-Page-Content & {
        &-tabs {
            margin-top: 25px;

            &-tab {
                margin-right: 5px;
            }
        }
    }
}

.estateViewPage {
    &.View-Estate-Root-Layout & {
        &-content {
            width: calc(100% - 10px);
            margin: 0 5px;

            @media (min-width: $mdBreakpoint) {
                width: calc(100% - 20px);
                margin: 0 10px;
            }

            &-header {
                margin: 0;
            }

            &-reload {
                //float: right;
                &-btn {
                    padding: 5px 15px;
                    float: right;
                    font-size: 20px;
                }
            }

            &-tabs {
                margin-top: 10px;
            }
        }
    }
}

.companyChainProtocolTab {
    &.companyChainProtocolTab & {
        &-content {
            padding: 20px 20px 0;
        }

        &-footer {
            padding: 0 20px 10px;

            &-section {
                margin-top: 25px;

                &-right {
                    button {
                        float: right;
                    }
                }
            }
        }

        &-handler {
            text-align: center;
            margin-top: 15vh;
            padding: 0 !important;
        }
    }

    &-select {
        width: 100%;
        height: 50vh;
        border: 2px solid #ccc;

        &--enabled {
            border-color: #4caf50;
        }

        &-item {
            padding: 2px;
            font-size: 13px;
        }
    }
}

.companyChainOfficeTable {
    &.companyChainOfficeTable & {
        &-addOffice {
            &-btn {
                padding: 5px 15px;
                float: right;
                font-size: 20px;
            }
        }
    }
}

.companyChainAccountingTable {
    &.companyChainAccountingTable & {
        &-save {
            &-btn {
                margin-top: 15px;
                float: right;
            }
        }
    }

    &-availableIcon {
        width: 40px;
        height: 40px;
        background-image: url("../img/redstar_icon.png");

        &-amount {
            padding-top: 10px;
            text-align: center;
            font-weight: 400;
        }
    }

    &-info {
        display: inline-block;
        font-size: 10px;
        background: #effcfd;
        padding: 3px;
        margin-top: 10px;
    }

    &-officeLink {
        font-weight: 400;
        margin-right: 5px;
    }
}

.companyChainAccountingSettingsTab {
    .companyChainAccountingSettingsTab & {
        &-container {
            margin-top: 30px;
        }
        &-select {
            width: 200px;
            margin-top: 27px;
        }
        &-save {
            &-btn {
                float: right;
            }
        }
    }
}
