// Box shadows
@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin text-shadow($shadow...) {
  -webkit-text-shadow: $shadow;
  -moz-text-shadow: $shadow;
  text-shadow: $shadow;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

// variables
$GapWidth: 15px;
$FieldLabelColor: #777; //B0B0B0

.Order-Handling-Grid-Layout {
  position: absolute;
  left: 0px;
  top: 0px;
  padding-top: 100px;
  padding-left: $GapWidth;
  padding-right: $GapWidth;  
  width: 100%;
  padding-bottom: 50px;
  background-color: white;

  display: grid;
  grid-template-columns: 0.4fr 4px 0.3fr 0.3fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: "info-area vertical-separator header-tabs header-tabs" "info-area vertical-separator form-area-1 form-area-2";
  grid-column-gap: $GapWidth;  

  .Info-Area {
    grid-area: info-area;
    overflow: auto;
  }

  .Vertical-Separator {
    grid-area: vertical-separator;
    background:#CCC;
  }

  .Header-Tabs {
    grid-area: header-tabs;

  }

  .Form-Area-1 {
    grid-area: form-area-1;
  }

  .Form-Area-2 {
    

    grid-area: form-area-2;
  }  
}

.Edit-Order-Styling {


  .Meter-Numbers-Area {
    height:170px;
    overflow:auto;
  }

  .Section-Header {
    margin-top:15px;
    background:#F5F5F5;
    height:30px;
    display:grid;
    grid-template-columns: 1fr 1fr 30px;

    .Section-Title {
      padding-top:5px;
      padding-left:5px;
      color:#858585;
      font-size:15px;
      font-weight: 400;
    }

    .Section-Title.Validated {
      color:#60B865;
    }

    .Section-Title.Invalid {
      color:#F6564D;
    }



    .Section-Control-Label {
      text-align:right;
      padding-top:5px;
      padding-right:20px;
      color: #4E4E4E;
    }

    .Section-Control-Help {
      text-align:right;
      padding-top:5px;
      padding-right:20px;
      color: #DDDDDD;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .Section-Order-Status {
      font-size:16px;
      padding-top:4px;
      color: #555555;
      white-space:nowrap;
      text-overflow: ellipsis;
      text-align:right;
    }

    .Section-Control-Element {
      height:30px;
      min-width:30px;

      .Add-Button, .Reset-Button {
        border: none;
        width:30px;
        height:30px;
        background-color: #DDDDDD;
        background-position: center center;
        background-repeat:no-repeat;
      }

      .Add-Button:hover, .Reset-Button:hover {
        background-color: #A740BA;
        transition-duration: 0.2s;
      }

      .Switch-Container {
        margin-left:-20px;
        margin-top:-3px;
        display:inline-block;
      }
    }
  }

  .Section-Header.No-Margin {
    margin-top: 0px;
  }



  .Section-Header:hover {
    .Section-Control-Help {
      color: #4E4E4E;
      transition-duration: 0.5s;
    }        
  }


  .Reference-Height-Section-Content {
    min-height:125px;

    table {
      width:100%;

      td {
        vertical-align: top;
        padding-bottom:3px;
      }

      td:nth-child(1) {
        width:15%;

        // special override fix for search inputs
        .MuiFormControl-root {
          //margin:0px !important;
          //padding-top: 0px !important;

          /*
          .MuiInputAdornment-root {
            opacity:0.5;
          }

          .MuiInput-underline:after {
            border-bottom: 2px solid #A740BA;
          }         

          .MuiInput-underline:hover:not(.Mui-disabled):before {
              border-bottom: 1px solid #A740BA !important;
          }            
          */
        


        }        
      }
      td:nth-child(2) {
        width:75%;
      }
    }

    .Autosuggest-Block {
      padding-top:5px;
      padding-bottom: 5px;             
    }
  }


  

  .Field-Preview-Label {
    color:$FieldLabelColor;
    font-size:14px;
  }

  .Field-Preview-Text {
    padding-left:10px;
    color: #4E4E4E;
    font-size:14px;
    font-weight: 400;

    //white-space: nowrap;
    //overflow: hidden;
    //text-overflow: ellipsis;    
  }

  

  .Input-Line {
    display:flex;
    flex-wrap: nowrap;
    justify-content: flex-start;

    padding-top: 10px;
    

    .Field-Label {
      color: $FieldLabelColor;
      font-size:14px;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 5px;
      padding-left:5px;
      flex-grow: 1;
      align-self: flex-end;

      strong {
        font-weight: 400;
      }
    }

    .Field-Label:first-child{
      padding-right: 5px;
      padding-left:0px;
    }


    .Large-Field-Control {
      flex-grow:100;
    }

    .Medium-Field-Control {
      flex-grow: 70;
    }

    .Small-Field-Control {
      flex-grow: 10;
    }

    /*special override fix to make material UI input as compact as possible*/
    .Large-Field-Control,
    .Medium-Field-Control,
    .Small-Field-Control {

      .MuiFormControl-root {
        margin:0px !important;
        padding-top: 5px !important;

        input {
          padding: 0px;
        }
      }
    }
  }

  .Power-Meter-Separator {
    height:0px;
    margin-top:15px;
    border-top: 2px dashed darkturquoise;
  }

  /* override fix for compact checkbox */
  .MuiCheckbox-root {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  /* override fix for checkbox label */
  .MuiFormControlLabel-label {
    color: $FieldLabelColor;
    font-weight:100;
  }  


  

}



.Order-Info-Header {

  display: grid;
  grid-template-columns: 0.5fr 0.5fr;  

  .Title {
    h3 {
      margin: 0px;
    }
  }

  .Buttons {
    text-align: right;
    button {
      margin-top:0px;
      padding:0px;
      height:38px;
      width:38px;

      svg {
        width:30px;
        height:30px;
      }
    }
  }
}

// Order Info Area
.Order-Info-Area {

    height: calc(100vh - 200px);
    overflow: auto;



  .Email-Body {
    margin-bottom: 30px;
    overflow-wrap: break-word;


    table {
      border-collapse: collapse;
      border:1px solid #AAA;

      td {
        padding:3px;
        border:1px solid #AAA;
      }
    }
  }
}



table.Order-List-Table {

  tr.Deleted-Order td {
    color:#AAAAAA;
  }

  tr.Selected-Order td {
    background:#f3c2fb;
  }

  td.Order-Id-Cell {
    color: #AAAAAA;
  }

  td.Meter-Number-Cell {
    sup {
      color:white;
      background-color:darkturquoise;
      padding-left:3px;
      padding-right:3px;
    }
  }

  td.Comment-Number-Cell {

    .MuiSvgIcon-root {
      color:#ddd;
    }

    span {
      display:inline-block;
      position:relative;
      text-align: center;
      width:20px;
      color:black;
      left:-27px;
      top:-17px;
      font-size: 10pt;
    }
  }


  td.Comment-Number-Cell:hover {
        cursor:pointer;

        .MuiSvgIcon-root {
          color:darkturquoise;
        }
  }

  td.Order-Status-Cell {
    .Order-Status-1 { }
    
    .Order-Status-2,
    .Order-Status-3 { color:limegreen; font-weight: 400; }

    .Order-Status-4,
    .Order-Status-5,
    .Order-Status-6,
    .Order-Status-7,
    .Order-Status-8,
    .Order-Status-9 { color: #df0e00 }

    .Order-Status-10 { color: darkturquoise;}

    /*
     1 UNTREATED
     2 READY_FOR_TRANSFER
     3 TRANSFER_COMPLETE
     4 TRANSFER_ERROR
     5 COLLECTIVE_MEASURING
     6 DUPLICATE
     7 REGRET
     8 REJECTED
     9 DELETED
    10 AWAITING_ANSWER
    */
  }
}


.Duplicate-Check-Result-Item {

  div {  
    padding-bottom: 5px;
    color:$FieldLabelColor;
    font-size:14px;    

    strong {
      color: #4E4E4E;
      font-weight: 400;
    }

    span.Power-Meter {
      border: 1px solid #28acc1;
      border-left: 2px solid #28acc1;
      padding:2px;
      display:inline-block;
      margin-left:5px;
      font-weight: 400;
      color: #28acc1;
    }
  }
}

.Send-Email-Window {
  position: fixed;
  width: 80vw;
  height: 80vh;
  top: 10vh;
  left: 10vw;
  background: white;
  @include box-shadow(3px 3px 6px rgba(#000, .16)) ;

  .Inner-Content {
    margin:20px;
  }
}