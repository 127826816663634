@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}


.Form-Tab-Header {
  font-size:12px;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  padding-bottom: 5px;

  .Tab-Button {
    display:inline-block;
    text-align:center;
    background: #fba839;
    border: none;
    color: white;
    height: 24px;
    width: 110px;
    padding-top:2px;
    padding-bottom:2px;
    @include border-radius(4px);
    margin-right: 2px;
    margin-bottom: 2px;
  }

  .Tab-Button.Email {
    background: #f6564d;
  }

  .Tab-Button.Email.Current {
    background: #3bb8cb;
  }



  /*
  .Tab-Button.Current {
    background: #3bb8cb;
  } 

  .Tab-Button.Status-Green {
    background: #60b865;
  }


  .Tab-Button.Status-Red {
    background: #f6564d;
  }  
  */

  .Tab-Button.Status-Saved {
    background: #60b865;
  }  

  .Tab-Button.Status-Saved.Current {
    background: #3bb8cb;
  }  

  .Tab-Button.Status-Unsaved {
    background: #f6564d;
  }  

  .Tab-Button.Status-Unsaved.Current {
    background: #3bb8cb;
  }    
}

