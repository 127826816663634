// Box shadows
@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow;
     -moz-box-shadow: $shadow;       
          box-shadow: $shadow;
}

@mixin text-shadow($shadow...) {
  -webkit-text-shadow: $shadow;
     -moz-text-shadow: $shadow;       
          text-shadow: $shadow;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}


// variables
$GapWidth: 15px;

.Email-Handling-Grid-Layout {
    position:absolute;
    left:0px;
    top:0px;
    padding-top:100px;
    padding-left:$GapWidth;
    padding-right:$GapWidth;
    width:100%;    
    padding-bottom:50px;
    background-color:white;

    display:grid;
    grid-template-columns: 0.4fr 0.3fr 0.3fr;
    grid-template-rows: auto 1fr;
    grid-template-areas: "info-area header-tabs header-tabs" "info-area work-area-1 work-area-2";
    grid-column-gap: $GapWidth;



    .Info-Area {
        grid-area: info-area;
        
        height: calc(100vh - 120px);
        overflow: auto;

        .Info-Header {
            h3 {
              margin: 0px;
            }
        }       
    }

    .Header-Tabs-Area {
      grid-area: header-tabs;
    }

    .Work-Area-1 {
        grid-area: work-area-1;

        .Email-Attachment-Area {
            margin-top: 20px;
            border: 1px solid #EEEEEE;
            padding-top: 10px;
            padding-left: 10px;
            padding-right: 10px;
            min-height: 110px;

            .Attachment-Card {
              display: inline-block;
              margin-bottom: 10px;
              margin-right: 10px;


              img {
                max-height: 100px;
                max-width:100px;
              }


              .Page-Count {
                font-size: 8pt;
                font-weight: 400;
                text-align:center;
                background:#3bb8cb;
                color: white;
              }

              .Title {
                font-size: 8pt;
                color: #555;
                text-align: center;
                font-style: italic;
              }
            }

            .Attachment-Card.With-Border {

              img {
                border: 1px solid #CCCCCC;
                padding-bottom:1px; // fix border clipping
              }
            }
        }

        // drop area when something can be dragged over
        .Email-Attachment-Area.Active {

            border: 1px solid #60B865;                     
            background: lighten(#60B865, 40%);
        }

        // drop area when something can be dropped
        .Email-Attachment-Area.CanDrop {

            border: 1px solid #F6564D;                     
            background: lighten(#F6564D, 30%);
        }        


        // drop area default style
        .Dropbox-Area {
            margin-top:40px;
            height:300px;
            background-repeat:no-repeat;
            background-position: center center;
            border: 3px dashed #D2D2D2;
        }

        // drop area when something can be dragged over
        .Dropbox-Area.Active {

                     
            border: 3px dashed #60B865;
        }

        // drop area when something can be dropped
        .Dropbox-Area.CanDrop {

                       
            border: 3px dashed #F6564D;
        }

    }

    .Work-Area-2 {
        grid-area: work-area-2;



        .New-Order-Area {
            margin-bottom:10px;

            .Order-Area-Header {
                border-bottom: 1px solid #DDDDDD;
                display:grid;
                grid-template-columns: 1fr 100px;

                .Order-Area-Title {
                    
                    font-size:15px;
                    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                    font-weight: 400;
                    //color:#434F5E;
                }

                .Order-Area-Title.Status-Saved {
                    color: #60B865;                     
                }

                .Order-Area-Title.Status-Unsaved {
                    color: #F6564D;
                }


                /*
                .Order-Area-Title.Status-Sent {
                    color: #60B865;                     
                }

                .Order-Area-Title.Status-Other {
                    color: #F6564D;
                }
                */

                .Order-Area-Buttons {
                    text-align:right;
                    
                    .Button {
                        display:inline-block;
                        cursor:pointer;
                        padding:5px;
                    }

                    .Button:hover {
                      background: #eeeeee;
                    }

                    .MuiSvgIcon-root {
                      width: 0.7em;
                      height:0.7em;
                    }
                }
            }

            .Order-Attachment-Area {
                margin-top: 20px;
                border: 1px solid #EEEEEE;
                padding-top: 10px;
                padding-left: 10px;
                padding-right: 10px;
                min-height: 110px;


                .Attachment-Card {
                  display:inline-block;
                  margin-bottom: 10px;
                  margin-right: 10px;

                  img {
                    max-height: 100px;
                    max-width:100px;
                  }

                  .Page-Count {
                    font-size: 8pt;
                    font-weight: 400;
                    text-align:center;
                    background:#3bb8cb;
                    color: white;
                  }

                  .Title {
                    font-size: 8pt;
                    color: #555;
                    text-align: center;
                    font-style: italic;
                  }
                }

                .Attachment-Card.With-Border {
                  img {
                    border: 1px solid #CCCCCC;
                    padding-bottom:1px; // fix border clipping
                  }                  
                }                
                
            }   


            // drop area when something can be dragged over
            .Order-Attachment-Area.Active {

                border: 1px solid #60B865;         
                background: lighten(#60B865, 40%);
            }

            // drop area when something can be dropped
            .Order-Attachment-Area.CanDrop {

                border: 1px solid #F6564D;
                background: lighten(#F6564D, 30%);
            }        

        }



    }


    .Column-Title {
        border-bottom: 1px solid #707070;
        margin-bottom:30px;

        img {
            vertical-align: text-bottom;
            margin-right: 5px;
        }
    }
}





// Email Header Panel

.Email-Header-Panel {
    margin-top: 10px;
    margin-bottom: 20px;
    padding-left:10px;
    padding-right:10px;
    border: 1px solid #CCCCCC;
    background: white;
    @include box-shadow(3px 3px 6px rgba(#000, .16)) ;
    margin-right:10px;


    table {
        margin: 10px;

        tbody tr {
            td:nth-child(1) {
                text-align:right;
                color:#858585;
            }

            td:nth-child(2) {
                
            }
        }
    }
}

// single page attachment window


$previewWidth: 60vh;
$previewHeight:90vh;

.Single-Page-File-Preview {
  width:$previewWidth;
  height:$previewHeight;
  background:white;
  @include box-shadow(3px 3px 6px rgba(#000, .16)) ;
  
  position:fixed; 
  left: calc(50% - 30vh);
  top: calc(50% - 45vh);

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr 80px;

  .Header-Grid-Area {
    border-bottom: 1px solid #555;
    text-align:center;
  }

  .Image-Grid-Area {
    margin-left:20px;
    margin-right:20px;
    text-align:center;    
    overflow:hidden;

    img {
      // disabled; size controlled by magnifier
      //max-width: 100%;
      //max-height:100%;
    }
  } 

  .Buttons-Grid-Area {

    display:flex;
    flex-direction: row;;
    justify-content: space-between;
    align-items: flex-start;

    padding-top: 10px;
    padding-left:20px;
    padding-right:20px;

    button.Icon-Button {
        padding:4px;      
        margin-right:10px;
    }

    button.Text-Button {
        margin-left:10px;
        padding-left:20px;
        padding-right:20px;
    }
  }
}

.Multi-Page-File-Preview {

  width: calc(60vh + 100px);
  height:$previewHeight;
  background:white;
  @include box-shadow(3px 3px 6px rgba(#000, .16)) ;
  
  position:fixed; 
  left: calc(50% - 30vh - 50px);
  top: calc(50% - 45vh);

  display: grid;
  grid-template-columns: 120px 1fr;
  grid-template-rows: 50px 1fr 80px;
  grid-template-areas: "header header" "thumbnails image" "buttons buttons";


  .Header-Grid-Area {
    grid-area: header;
    border-bottom: 1px solid #555;
    text-align:center;
  }

  .Buttons-Grid-Area { 

    grid-area: buttons;
    display:flex;
    flex-direction: row;;
    justify-content: space-between;
    align-items: flex-start;

    padding-left:20px;
    padding-right:20px;
    padding-top: 10px;

    button.Icon-Button {
        padding:4px;      
    }

    button.Text-Button {
        padding-left:20px;
        padding-right:20px;
    }
  }

  .Image-Grid-Area { 
    margin-left:20px;
    margin-right:20px;
    margin-top:20px;
    text-align:center;
    grid-area: image; 
    overflow:hidden;

    .Current-Page-Title {
      text-align:center;
      font-size:16px;
      font-weight: 400;
    }

    img {
      // disabled; size controlled by magnifier
      //max-width: 100%;
      //max-height:100%;      
    }
  }

  .Thumbnails-Grid-Area { 
    grid-area: thumbnails; 
    background:#554E54;
    overflow-y:scroll;
    text-align:center;
    padding-top:10px;

    > div {
      margin-top:10px;

      img {
        min-height:70px;
        max-width:70px;
        border: 3px solid transparent;
      }
      img:hover {
        border: 3px solid orange;
      }

      img:active {
        border: 3px solid red;
      }
    }

    > div > div {
      color:white;
      text-align:center;
      font-weight: 10px;
      padding:4px;
    }
  }
}


$genericFilePreviewWidth: 60vh;
$genericFilePreviewHeight:40vh;

.Generic-File-Preview {
  width:$genericFilePreviewWidth;
  height:$genericFilePreviewHeight;
  background:white;
  @include box-shadow(3px 3px 6px rgba(#000, .16)) ;
  
  position:fixed; 
  left: calc(50% - 30vh);
  top: calc(50% - 20vh);

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 80px;

  .Info-Grid-Area {
    margin-left:20px;
    margin-right:20px;
    margin-top:20px;
    text-align:center;    
    overflow:auto;

    table {
      td:nth-child(1) {
        font-weight: 400;
        min-width:100px;
        text-align:left;
      }
    }
  } 

  .Buttons-Grid-Area {


    display:flex;
    flex-direction: row;;
    justify-content: space-between;
    align-items: flex-start;

    padding-left:20px;
    padding-right:20px;

    button.Icon-Button {
        padding:4px;      
    }

    button.Text-Button {
        padding-left:20px;
        padding-right:20px;
    }
  }
}



table.Email-List-Table {

  tr.Selected-Email td {
    background:#f3c2fb;
  }

  td.Email-Id-Cell {
    color: #AAAAAA;
  }

  td {
    span.Email-Inspected {
      color:limegreen; font-weight: 400;
    }

    span.Email-Not-Inspected {
      color: #df0e00;
    }
  }
}

