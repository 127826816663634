.Power-Company-Details, .Product-Details {
  table {
    td {
      padding: 5pt;
    }

    td:nth-child(1) {
      color: #777;
    }

    td:nth-child(2) {
      padding-left: 10pt;
      font-weight: 400;
    }
  }
}

